#root{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    text-align: center;
  }

canvas {
  border: 1.5px solid #724F08;
  border-radius: 3px;
}

@media only screen and (min-width: 992px){
  canvas {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
}

body{
  background-color: black;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
  .btn-send img{
    background-color: rgba(145, 92, 182, .0);
    border-radius: 10px;

  }
  
  .btn-send:hover {
    box-shadow: 0px 0px 4px 4px rgba(145, 92, 182, .9); 
  }

  .btn-send img:hover {
    border-radius: 0.75vh;
    background: unset;

  }


  .btn-send{
    border-radius: 1vh;
  }

  .btn-send:active {
    margin-top: 5px;

  }
  *::selection {
    color: none;
    background: none;
}

textarea::selection, input::selection {
  color: white !important;
  background-color: #8810B7 !important;
}
#warning-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#pleaseturnyourdevice > div {
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

#pleaseturnyourdevice img {
  width: 50vw;
  height: auto;
  align-self: center;
}

#form-claim {
  position: fixed;
  top: 2.5vw;
  overflow: hidden;
  background-color: #FFFFFF;
  z-index: 10;
  width: 50vw;
  height: 90vh;
  border-radius: 1.5vw;
  left: 25vw;
}

input[type='text'],input[type='number'],textarea {font-size:16px;}
body{ -webkit-text-size-adjust:none;}
/* input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
} */